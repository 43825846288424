




































import { Component, Watch, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ListBooks from '@/components/ListBooks/ListBooks.vue';
import Ranking from '@/components/Ranking/index.vue';
import Footer from '@/components/Footer/Footer.vue';

import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';
import LastVideo from '@/widgets/Video/LastVideo/LastVideo.vue';

import BoxSimulator from '../../components/BoxSimulator/BoxSimulator.vue';

import SimulationService from '@/services/Simulation/SimulationService';

import SimulatedFeedbackStatus from '@/mixins/SimulatedFeedbackStatus';

import { hasPermissionSimulatedByPermissionId } from '@/share/Util/Permissions/simulated';
import { orderList } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import { NewSimulated, Simulated } from '../../interface/ISimulated';

import { SIMULATED_COVERS } from '@/constant/SimulatedCovers';

const ORDER_SIMULATED = {
  available: 1,
  notAvailable: 2
};

@Component({
  components: {
    PageHeader,
    ListBooks,
    LastVideo,
    Footer,
    BoxSimulator,
    RealtimeUsers,
    Ranking
  },
  mixins: [SimulatedFeedbackStatus]
})
export default class Books extends Vue {
  private listSimulated: Array<NewSimulated> | Array<any> = [];
  private hasPermissionInSomeSimulated = false;
  private isLoading = true;

  private SimulationService = new SimulationService();

  created() {
    this.loadBooks();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  async loadBooks() {
    await this.setSimulated();
  }

  async setSimulated() {
    try {
      this.isLoading = true;
      const simulation = await this.SimulationService.getListSimulations();

      let newListSimulated: Array<Record<string, any>> = [];

      if (simulation?.data) {
        newListSimulated = simulation.data.map((simulated: Simulated) => ({
          id: simulated.id,
          color: simulated.color,
          thumb: SIMULATED_COVERS[Number(simulated.id)],
          slug: simulated.url,
          link: `/simulado/${simulated.id}`,
          type: 'simulation',
          title: simulated.title,
          disable: false,
          order: ORDER_SIMULATED.available
          // disable: !hasPermissionSimulatedByPermissionId(simulated.id),
          // order: !hasPermissionSimulated(simulated.id) ? ORDER_SIMULATED.notAvailable : ORDER_SIMULATED.available,
        }));

        this.listSimulated = orderList(newListSimulated, 'order');
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os simulados.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('listSimulated')
  verifyPermissionInSomeSimulated() {
    this.hasPermissionInSomeSimulated = this.listSimulated.some(
      (simulated: NewSimulated) => !simulated.disable
    );
  }
}
