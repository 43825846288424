












import { Component, Prop, Vue } from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';

import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

@Component({
  components: {
    BoxContainer
  }
})
export default class BoxSimulator extends Vue {
  @Prop() hasPermissionSimulated!: boolean;

  private modalInstance = new ActiveModal();

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  handleGoSimulator() {
    if (this.isPlanFree || !this.hasPermissionSimulated) {
      this.setShowModal();
      return;
    }

    this.$router.push({
      name: 'EnemSimulator'
    });
  }

  setShowModal() {
    if (this.isPlanFree) this.showModal('modalPlanUpdate');
    else this.showModal('modalNotContentPlan');
  }

  showModal(id: string) {
    this.modalInstance.activeModal(id);
  }
}
